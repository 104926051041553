export const countriesInfo = [
  {
    id: "UK",
    country: "United Kingdom",
    why: "High quality of life, strong job market, and a culturally rich environment with a diverse population.",
    systemOfEducation:
      "Three-tier system: primary, secondary, and higher education; universities are well-renowned globally.",
    averageTuitionCosts:
      "£10,000 - £38,000 per year for international students.",
    facts: [
      "Home to prestigious universities like Oxford and Cambridge.",
      "National Health Service (NHS) offers free healthcare.",
      "Rich historical heritage with famous landmarks.",
      "The UK has four distinct countries: England, Scotland, Wales, and Northern Ireland.",
      "Home to a multicultural and diverse population.",
      "Universities offer diverse fields of study and research opportunities.",
      "Moderate climate with varying weather conditions.",
    ],
    immigrationOptions: [
      {
        type: "Student Visa (Tier 4)",
        description:
          "Allows international students to study in the UK; can work part-time and apply for a post-study work visa.",
      },
      {
        type: "Skilled Worker Visa",
        description:
          "For skilled workers with a job offer in the UK; leads to permanent residence after five years.",
      },
      {
        type: "Innovator Visa",
        description:
          "For individuals looking to start a business in the UK; requires endorsement by an approved body.",
      },
      {
        type: "Family Visa",
        description:
          "For family members of UK residents or citizens; allows joining or remaining with family.",
      },
      {
        type: "Global Talent Visa",
        description:
          "For leaders in academia, research, digital technology, and arts; does not require a job offer.",
      },
    ],
  },
  {
    id: "US",
    country: "United States",
    why: "Opportunities for personal and professional growth, high standard of education, and technological advancements.",
    systemOfEducation:
      "Primary, secondary, and tertiary education system; highly reputed universities with diverse programs.",
    averageTuitionCosts:
      "$20,000 - $50,000 per year for international students.",
    facts: [
      "Diverse landscape and climate across states.",
      "Top-ranked universities like MIT, Harvard, and Stanford.",
      "Known as the 'land of opportunity' with a strong economy.",
      "English is the primary language, with a large Spanish-speaking population.",
      "Renowned for technological and scientific advancements.",
      "High cultural diversity across the country.",
      "Several visa options for students and skilled workers.",
    ],
    immigrationOptions: [
      {
        type: "Student Visa (F-1)",
        description:
          "Allows international students to pursue studies in the U.S.; eligible for work under OPT or CPT programs.",
      },
      {
        type: "Employment-based Green Cards (EB-1 to EB-5)",
        description:
          "For skilled workers, investors, and special talents; leads to permanent residency.",
      },
      {
        type: "H-1B Visa",
        description:
          "For skilled workers with specialized knowledge; requires sponsorship by a U.S. employer.",
      },
      {
        type: "Diversity Visa Lottery",
        description:
          "Lottery program for applicants from underrepresented countries to obtain U.S. permanent residency.",
      },
      {
        type: "Family-sponsored Green Cards",
        description:
          "Allows U.S. citizens and residents to sponsor family members for permanent residency.",
      },
    ],
  },
  {
    id: "CA",
    country: "Canada",
    why: "Quality healthcare, high standard of living, and an inclusive society welcoming immigrants.",
    systemOfEducation:
      "Three-tier system with free primary and secondary education; renowned universities.",
    averageTuitionCosts:
      "CAD 7,000 - CAD 30,000 per year for international students.",
    facts: [
      "Known for its beautiful landscapes and cold winters.",
      "Bilingual country (English and French).",
      "Healthcare is publicly funded and generally free.",
      "Multicultural society with a high rate of immigration.",
      "High quality of life and work-life balance.",
      "Offers a wide range of immigration programs.",
      "Highly regarded education system with strong public universities.",
    ],
    immigrationOptions: [
      {
        type: "Express Entry",
        description:
          "Points-based system for skilled workers; applicants with high scores receive invitations to apply for permanent residence.",
      },
      {
        type: "Provincial Nominee Program (PNP)",
        description:
          "Allows provinces to nominate immigrants based on their specific labor market needs; leads to permanent residency.",
      },
      {
        type: "Study Permit",
        description:
          "For international students; allows work while studying and eligibility for post-graduation work permit.",
      },
      {
        type: "Family Sponsorship",
        description:
          "Allows Canadian citizens and permanent residents to sponsor family members to come to Canada.",
      },
      {
        type: "Canadian Experience Class (CEC)",
        description:
          "For individuals with Canadian work experience; part of the Express Entry program for permanent residency.",
      },
    ],
  },
  {
    id: "IE",
    country: "Ireland",
    why: "High-quality education, friendly people, and a growing tech industry.",
    systemOfEducation:
      "Structured into primary, secondary, and tertiary education; strong emphasis on STEM fields.",
    averageTuitionCosts:
      "€9,000 - €25,000 per year for international students.",
    facts: [
      "English-speaking with a rich cultural heritage.",
      "Strong economy, particularly in technology and pharmaceuticals.",
      "Friendly locals and a welcoming environment.",
      "Beautiful landscapes with historical landmarks.",
      "Healthcare is free or affordable.",
      "One of the fastest-growing economies in Europe.",
      "Safe country with low crime rates.",
    ],
    immigrationOptions: [
      {
        type: "Student Visa",
        description:
          "Allows international students to study in Ireland; options for post-study work permits are available.",
      },
      {
        type: "Critical Skills Employment Permit",
        description:
          "For professionals in critical fields like IT and engineering; fast-tracks residency.",
      },
      {
        type: "General Employment Permit",
        description:
          "For skilled workers with job offers in Ireland; can lead to residency.",
      },
      {
        type: "Family Reunification",
        description:
          "Allows family members of Irish citizens and residents to live in Ireland.",
      },
      {
        type: "Investor Visa",
        description:
          "For individuals who invest in Irish businesses; requires a significant financial commitment.",
      },
    ],
  },
  {
    id: "AU",
    country: "Australia",
    why: "High standard of living, diverse cities, and a strong educational system with a focus on research.",
    systemOfEducation:
      "Primary, secondary, and tertiary levels; well-regarded for its universities and research facilities.",
    averageTuitionCosts:
      "AUD 20,000 - AUD 45,000 per year for international students.",
    facts: [
      "Home to world-class universities like the University of Sydney.",
      "Famous for beautiful beaches and outdoor lifestyle.",
      "Has a unique and diverse wildlife.",
      "Healthcare system is a mix of public and private.",
      "English-speaking, making it easier for English-speaking immigrants.",
      "Diverse and multicultural population.",
      "Stable economy with strong job market in health, education, and IT.",
    ],
    immigrationOptions: [
      {
        type: "Student Visa",
        description:
          "Allows international students to study in Australia; eligible for work and post-study work visas.",
      },
      {
        type: "Skilled Independent Visa",
        description:
          "Points-based visa for skilled workers; allows for permanent residency.",
      },
      {
        type: "Employer Nomination Scheme",
        description:
          "For skilled workers sponsored by Australian employers; leads to permanent residency.",
      },
      {
        type: "Family Visa",
        description:
          "For partners, parents, and children of Australian citizens or residents.",
      },
      {
        type: "Working Holiday Visa",
        description:
          "Allows young adults to work and travel in Australia for up to a year.",
      },
    ],
  },
  {
    id: "FR",
    country: "France",
    why: "Rich cultural heritage, excellent healthcare system, and top-tier educational institutions.",
    systemOfEducation:
      "Four levels: maternelle (preschool), primaire (elementary), collège (middle school), and lycée (high school); higher education is highly regarded.",
    averageTuitionCosts:
      "€3,000 - €10,000 per year for international students.",
    facts: [
      "Known for its art, history, and cuisine.",
      "France has a strong healthcare system.",
      "French is the official language; many programs are also offered in English.",
      "Home to world-famous landmarks like the Eiffel Tower.",
      "Universities focus heavily on research and development.",
      "Part of the Schengen Area, allowing for easy European travel.",
      "High quality of life with a focus on work-life balance.",
    ],
    immigrationOptions: [
      {
        type: "Student Visa",
        description:
          "Allows international students to pursue studies in France; offers work options during and after study.",
      },
      {
        type: "Talent Passport",
        description:
          "For skilled professionals, researchers, and artists; allows long-term residence.",
      },
      {
        type: "Family Visa",
        description:
          "For family members of French citizens or residents; grants long-term residence.",
      },
      {
        type: "Temporary Worker Visa",
        description:
          "For workers with job offers in France; valid for up to 12 months.",
      },
      {
        type: "Entrepreneur Visa",
        description:
          "For individuals establishing businesses; must meet investment requirements.",
      },
    ],
  },
  {
    id: "NL",
    country: "Netherlands",
    why: "Progressive society, strong economy, and excellent work-life balance.",
    systemOfEducation:
      "Three-tiered education system with compulsory schooling until 16; universities are highly respected.",
    averageTuitionCosts:
      "€6,000 - €15,000 per year for international students.",
    facts: [
      "Dutch and English are widely spoken.",
      "Famous for canals, tulips, and windmills.",
      "Bicycle-friendly with extensive cycling paths.",
      "Part of the Schengen Area.",
      "Home to the International Court of Justice.",
      "Emphasis on a balanced and healthy lifestyle.",
      "Ranked high for overall quality of life.",
    ],
    immigrationOptions: [
      {
        type: "Student Visa",
        description:
          "Allows international students to study in the Netherlands; offers work options and post-study visas.",
      },
      {
        type: "Highly Skilled Migrant Visa",
        description:
          "For professionals with high skills and job offers; fast-tracks residency.",
      },
      {
        type: "Entrepreneur Visa",
        description:
          "For entrepreneurs; requires meeting specific investment and business criteria.",
      },
      {
        type: "Family Visa",
        description:
          "For family members of Dutch residents or citizens; offers residence permits.",
      },
      {
        type: "Search Year Visa",
        description: "One-year visa for recent graduates to seek employment.",
      },
    ],
  },
  {
    id: "CY",
    country: "Cyprus",
    why: "Warm climate, Mediterranean lifestyle, and affordable living.",
    systemOfEducation:
      "Three-tier education system; good range of programs for international students.",
    averageTuitionCosts: "€3,500 - €8,000 per year for international students.",
    facts: [
      "Known for its beautiful beaches and mild winters.",
      "Greek and Turkish are the official languages.",
      "Low cost of living compared to other European countries.",
      "Member of the European Union.",
      "Highly ranked for safety and quality of life.",
      "English is widely spoken, especially in cities.",
      "Offers residence permits to property buyers.",
    ],
    immigrationOptions: [
      {
        type: "Student Visa",
        description:
          "Allows international students to study in Cyprus; offers limited work options.",
      },
      {
        type: "Permanent Residence Permit",
        description:
          "For individuals investing in property or business; allows long-term residence.",
      },
      {
        type: "Family Visa",
        description:
          "For family members of Cypriot residents or citizens; provides residence permits.",
      },
      {
        type: "Temporary Residence Visa",
        description:
          "For visitors planning to stay for more than three months; renewable for longer stays.",
      },
      {
        type: "Employment Visa",
        description:
          "For workers with job offers in Cyprus; must be employer-sponsored.",
      },
    ],
  },
  {
    id: "GE",
    country: "Germany",
    why: "Strong economy, world-renowned universities, and excellent quality of life.",
    systemOfEducation:
      "Dual education system with a focus on practical and theoretical learning; free education at public universities.",
    averageTuitionCosts:
      "€0 - €3,000 per year for most public universities (administrative fees).",
    facts: [
      "Known for its engineering and technology sectors.",
      "Member of the European Union and Schengen Area.",
      "Has a rich cultural and historical heritage.",
      "German is the official language; English is commonly spoken.",
      "High quality of public healthcare and social services.",
      "Universities offer world-class research opportunities.",
      "Free public education for both citizens and international students at public universities.",
    ],
    immigrationOptions: [
      {
        type: "Student Visa",
        description:
          "Allows international students to study in Germany; provides options for part-time work and post-study permits.",
      },
      {
        type: "EU Blue Card",
        description:
          "For highly skilled professionals with job offers; leads to permanent residency.",
      },
      {
        type: "Job Seeker Visa",
        description:
          "Allows individuals to stay in Germany for six months to search for employment.",
      },
      {
        type: "Family Reunion Visa",
        description:
          "For family members of German citizens or residents; grants long-term residence.",
      },
      {
        type: "Freelance Visa",
        description:
          "For self-employed individuals or freelancers; allows residence based on income and job type.",
      },
    ],
  },
  {
    id: "NZ",
    country: "New Zealand",
    why: "High quality of life, stunning landscapes, and a welcoming culture.",
    systemOfEducation:
      "Three-tier education system with reputable universities; focus on quality and practical learning.",
    averageTuitionCosts:
      "NZD 22,000 - NZD 35,000 per year for international students.",
    facts: [
      "Known for beautiful nature and outdoor activities.",
      "English is the official language.",
      "Moderate climate with mild winters and warm summers.",
      "Offers high quality of life and good work-life balance.",
      "Strong focus on environmental sustainability.",
      "Easy immigration pathways for skilled workers.",
      "High level of safety and low crime rates.",
    ],
    immigrationOptions: [
      {
        type: "Student Visa",
        description:
          "Allows international students to study in New Zealand; offers options for work and post-study permits.",
      },
      {
        type: "Skilled Migrant Category Resident Visa",
        description:
          "Points-based visa for skilled professionals; leads to permanent residency.",
      },
      {
        type: "Work to Residence Visa",
        description:
          "For individuals with long-term job offers; leads to residency after two years.",
      },
      {
        type: "Family Visa",
        description:
          "For family members of New Zealand citizens or residents; provides residence permits.",
      },
      {
        type: "Entrepreneur Visa",
        description:
          "For individuals establishing businesses; requires meeting investment criteria.",
      },
    ],
  },
];
