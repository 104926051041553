<template>
    <Nav />
    <div class="content-container">
        <div class="about-header">
            <h1>Our coverage</h1>
        </div>

        <div class="country-grid">
            <div class="country" v-for="country in countries">
                <router-link :to="`/coverage/${country.id}`">
                    <img :src="require(`../assets/${country.name.split('_').join(' ')}.jpg`)"
                        :alt="`Flag of ${country.name}`">
                    <h3>{{ country.name.split('_').join(" ") }}</h3>
                </router-link>
            </div>
        </div>
    </div>
    <Footer />
</template>

<script setup>
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
import { ref } from 'vue'

const countries = ref([
    {
        id: 'UK',
        name: 'United_Kingdom'
    },
    {
        id: 'US',
        name: 'United_States'
    },
    {
        id: 'CA',
        name: 'Canada'
    },
    {
        id: 'IE',
        name: 'Ireland'
    },
    {
        id: 'AU',
        name: 'Australia'
    },
    {
        id: 'FR',
        name: 'France'
    },
    {
        id: 'NL',
        name: 'Netherlands'
    },
    {
        id: 'CY',
        name: 'Cyprus'
    },
    {
        id: 'GE',
        name: 'Germany'
    },
    {
        id: 'NZ',
        name: 'New_Zealand'
    },
])
</script>

<style lang="scss">
.content-container {
    @include flex(column, flex-start, flex-start, 1em, nowrap);
    padding: $page-padding--left;

    .about-header {
        width: 100%;
        padding: 20px;
        background-color: $color-primary;
        border-radius: 8px;

        h1 {
            font-size: var(--font-size-fluid-3);
            color: $text-muted;
        }
    }

    .country-grid {
        width: 100%;
        @include flex(row, center, flex-start, 2em, wrap);

        .country {
            width: calc(33.3% - 2em);
            padding: .5em;
            background-color: #eee;
            border-radius: var(--radius-2);
            box-shadow: var(--shadow-1);

            &:hover {
                background-color: #ddd;
                box-shadow: none;
            }

            a {
                width: 100%;

                h3 {
                    font-size: 24px;
                }

                img {
                    width: 100%;
                    height: 300px;
                    object-fit: cover;
                }
            }
        }
    }
}

@media (max-width: 660px) {
    .content-container {
        .country-grid {
            justify-content: center;

            .country {
                width: calc(50% - 2em);
            }
        }
    }
}

@media (max-width: 475px) {
    .content-container {
        .country-grid {
            justify-content: center;

            .country {
                width: 100%;
            }
        }
    }
}
</style>