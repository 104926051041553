import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '@/views/AboutView.vue'
import CoverageView from '@/views/CoverageView.vue'
import CountryView from '@/views/CountryView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  }, 
  {
    path: '/about',
    name: 'about',
    component: AboutView
  }, 
  {
    path: '/coverage',
    name: 'coverage',
    component: CoverageView
  },
  {
    path: '/coverage/:country',
    name: 'country',
    component: CountryView
  }, 
  {
    path: '/services',
    name: 'services',
    component: () => import('@/views/ServicesView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
