<template>
    <Nav />
    <main class="country-main">
        <aside v-if="countryInfo">
            <img :src="require(`../assets/${countryInfo.country}.jpg`)" alt="">
        </aside>
        <div class="content-container" v-if="countryInfo">
            <div class="section">
                <h1>
                    Why {{ countryInfo.country }}?
                </h1>
                <p>
                    {{ countryInfo.why }}
                </p>
            </div>

            <div class="section">
                <h2>
                    System of Education
                </h2>
                <p>
                    {{ countryInfo.systemOfEducation }}
                </p>
            </div>

            <div class="section">
                <h2>
                    Average tuition costs
                </h2>
                <p>
                    {{ countryInfo.averageTuitionCosts }}
                </p>
            </div>

            <div class="section">
                <h2>
                    Here are some facts about {{ countryInfo.country }}
                </h2>

                <ul>
                    <li v-for="(fact, index) in countryInfo.facts" :key="fact">
                        <b>{{ index + 1 }}.</b> {{ fact }}
                    </li>
                </ul>
            </div>

            <div class="section">
                <h2>Your immigration options</h2>

                <ul class="second-list">
                    <li v-for="option in countryInfo.immigrationOptions" :key="option">
                        <h3>
                            {{ option.type }}
                        </h3>
                        <p>
                            {{ option.description }}
                        </p>
                    </li>
                </ul>
            </div>
        </div>

    </main>

    <Footer />
</template>

<script setup>
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
import { countriesInfo } from '@/data/countries'
import { ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'

const countryInfo = ref(null),
    route = useRoute()

onMounted(() => {
    console.log(countriesInfo)

    countriesInfo.forEach(country => {
        if (country.id === route.params.country) {
            countryInfo.value = country
        }
    })

    console.log(countryInfo.value)
})
</script>

<style lang="scss">
.country-main {
    display: flex;
    flex-wrap: wrap;

    .content-container {
        flex: 1;

        .section {
            @include flex(column, flex-start, flex-start, .5em, nowrap);

            ul {
                @include flex(column, flex-start, flex-start, .3em, nowrap);

                &.second-list {
                    li {
                        @include flex(column, flex-start, flex-start, .3em, nowrap);
                    }
                }
            }
        }
    }

    aside {
        flex: 0 0 700px;
        padding: 20px;
        text-align: center;

        img {
            max-width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 8px;
        }
    }
}

@media (max-width: 768px) {
    .country-main {
        flex-direction: column;
        flex-wrap: wrap-reverse;
    }

    aside {
        flex: 1;
        max-height: 450px;

        img {
            height: 300px;
        }
    }
}
</style>