<template>
    <footer>
        <div class="footer__wrapper">
            
            <div class="footer__section">
                <router-link to="/" class="logo">
                    <img :src="require('@/assets/SQNTT LOGO .png')" alt="site logo">
                </router-link>
                <h2>
                    Contact us
                </h2>

                <div class="contact-section">
                    <a target="_blank" href="tel:+234 803 665 6521"><i class="pi pi-phone"></i></a>
                    <a target="_blank" href="https://wa.link/f2cm9b"><i class="pi pi-whatsapp"></i></a>
                    <a target="_blank" href="https://www.instagram.com/sqntt_/"><i class="pi pi-instagram"></i></a>
                    <a target="_blank" href="https://www.facebook.com/profile.php?id=61552766440928"><i class="pi pi-facebook"></i></a>
                </div>
                <span>&copy; 2024. All rights reserved</span>
            </div>

            <div class="footer__section">
                <div class="section">
                    <h3>
                        Company
                    </h3>
                    <router-link to="/about">
                        About us
                    </router-link>
                    <router-link to="/services">
                        Our services
                    </router-link>
                    <router-link to="/coverage">
                        Coverage
                    </router-link>
                </div>
            </div>

            <div class="footer__section">
                <div class="section">
                    <h3>Our Services at a glance</h3>
                    <span>Education Counseling</span>
                    <span>VISA Assitance (Study, Work & Visitor)</span>
                    <span>Job Placement Assitance</span>
                    <span>Immigration Consultation</span>
                    <span>Business & Investment Programs</span>
                    <span>Scholarship Assitance</span>
                    <span>Accomodation Services</span>
                    <span>Flight Booking</span>
                    <span>Travel & Tour</span>
                    <span>Pre Departure and Post-Arrival Services</span>
                </div>
            </div>
            <img :src="require('../assets/footer-image.jpg')" alt="">
        </div>
    </footer>
</template>

<script setup>
</script>

<style lang="scss">
footer {
    padding: 50px;
    min-height: 50dvh;
    background-color: $color-primary-light;



    .footer__wrapper {
        @include flex(row, flex-start, space-between, 0, wrap);
        width: 100%;

        img {
            width: 40%;
            height: 80dvh;
            object-fit: cover;
            border-radius: var(--radius-2);
        }



        .footer__section {
            @include flex(column, flex-start, flex-start, .5em, nowrap);

            .logo {
                img {
                    height: 96px;
                    width: auto;
                }
            }

            .contact-section {
                @include flex(row, center, center, 1em, nowrap);

                a {
                    display: block;
                    text-align: center;
                    @include flex(column, center, center, 0, nowrap);
                    height: 36px;
                    width: 36px;
                    padding: 2px;
                    background-color: $color-primary-darker;
                    border-radius: var(--radius-round);
                    border: solid var(--border-size-1) $color-primary-dark;

                    i {
                        font-size: 18px;
                        color: $text-muted;
                    }
                }
            }

            .section {
                @include flex(column, flex-start, flex-start, .25em, nowrap);

                h3 {
                    margin-bottom: 8px;
                }

                a {
                    color: $text-secondary;
                    &:hover {
                        text-decoration: underline;
                    }
                }

                span {
                    color: $text-secondary;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    footer {
        padding: 24px;
        .footer__wrapper {
            gap: 1em;
            img {
                width: 100%;
                height: 50dvh;
            }
            .footer__section {
                width: 100%;
            }
        }
    }
}
</style>