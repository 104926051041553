<template>
    <div>
        <!-- This span will hold the typed text -->
        <span ref="typedText"></span>
    </div>
</template>

<script setup>
import { onMounted, onBeforeUnmount, ref } from 'vue';
import Typed from 'typed.js';

// Props (define as reactive variables)
const props = defineProps({
    strings: {
        type: Array,
        required: true,
    },
    typeSpeed: {
        type: Number,
        default: 50,
    },
    backSpeed: {
        type: Number,
        default: 50,
    },
    loop: {
        type: Boolean,
        default: true,
    },
});

// Refs
const typedText = ref(null); // Reference to the DOM element
let typedInstance = null;   // Store the Typed.js instance

// Lifecycle hooks
onMounted(() => {
    // Initialize Typed.js when the component is mounted
    typedInstance = new Typed(typedText.value, {
        strings: props.strings,
        typeSpeed: props.typeSpeed,
        backSpeed: props.backSpeed,
        loop: props.loop,
    });
});

onBeforeUnmount(() => {
    // Destroy the instance before the component is destroyed
    if (typedInstance) {
        typedInstance.destroy();
    }
});
</script>
